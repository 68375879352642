#main-content {
    display: flex;

    flex: 5;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}

#menu-icon {
    position: absolute;
    
    margin-top: 1.5vh;
    margin-left: 1vw;
}

#menu-icon:hover {
    cursor: pointer;
}

#menu-icon svg {
    transition: all 0.75s ease-in-out;
}

#menu-icon:hover svg {
    color: var(--dark-theme-orange);
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;

    flex: 1;

    margin: 0;
    margin-bottom: 2.5vh;

    max-width: 100%;

    font-family: "Roboto", sans-serif;
    color: var(--dark-theme-font-colour);
}

.content h1:not(:first-child),
.content h2, 
.content h3 { 
    margin-top: 4.25vh;
}

.content h1,
.content h2, 
.content h3 {
    margin-bottom: 1vh;
    border-bottom: 1px solid var(--dark-theme-orange);

    font-family: 'desire-pro', serif;
    text-align: left;
    
    width: fit-content;
}

.content-gutter {
    flex: 1
}

.padless {
    padding: 0 !important;
}

.padless .row {
    margin: 0;
}