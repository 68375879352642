body {
    min-height: 100vh;
}

body, 
#site {
    display: flex;

    background-color: var(--bg-colour);
    margin: 0;
    padding: 0;

    --fa-primary-color: var(--dark-theme-orange);
    --fa-secondary-color: var(--dark-theme-font-colour);

    max-width: 100vw;
}

#root {
    display: flex;
    flex: 1;
}

#site {
    flex: 1;
}

blockquote {
    background-color: #DEDEDE;

    padding-left: 10px;
    border-left: solid 10px #FFC982;

    font-style: italic;
    color: #5E5E5E;
}

button:hover {
    cursor: pointer;
}

button:focus {
    outline: 0;
}

.center-text {
    text-align: center;
    vertical-align: middle;
}

.icon-link {
    display: inline-block;
    text-align: center;
    margin: 0 1vw;
}