@media screen and (max-width: 1279px) {
    #navigation-menu {
        position: fixed;

        width: 100%;

        bottom: 0;
        background-color: var(--bg-colour);

        /* Need this to be large enough such that it's above everything else. */
        z-index: 32;

        flex-direction: row;

        align-items: center;
    }

    /*
        I don't see a reason to make these responsive changes to the LinkedIcon component.
        Those components SHOULDN'T need to intrinsically worry about being responsive.
        Only the components that use them should be worried about it.
        If we end up having the same code copy-pasted in different components, we can look to making it a CSS class.
    */
    #navigation-menu .linked-icon {
        flex-direction: column;

        align-items: center;
    }

    #navigation-menu .linked-icon > svg {
        margin-right: 0;
        padding-top: 1.25vh;

        font-size: 2em;
    }

    #navigation-menu > .linked-icon-wrapper {
        margin: 0;
        padding-bottom: 0.25vh;
    }

    #navigation-menu p.link-description {
        margin: 0vh 2.5vw;
    }
}

@media screen and (min-width: 1280px) {
    #navigation-menu {
        position: fixed;
        
        flex-direction: column;
    }

    #navigation-menu .linked-icon.selected-linked-icon::before {
        border-left: 1.5px solid var(--dark-theme-orange);
    }

    #navigation-menu .linked-icon::before {
        content: "";
        position: absolute;
        
        height: 100%;
        left: 0px;
    
        box-sizing: inherit;
        border-left: 1.5px solid var(--dark-theme-font-colour);
    }

    #navigation-menu .linked-icon:not(.selected-linked-icon)::after {
        content: "";
        position: absolute; 
        
        height: 0%;
        left: 0px;
        
        box-sizing: inherit;
        border-left: 1.5px solid transparent;
    
        transition: height 0.5s, border-color 0.5s; 
    }
    
    #navigation-menu .linked-icon:not(.selected-linked-icon):hover::after {
        height: 100%;
    
        border-color: var(--dark-theme-orange);
    }
}