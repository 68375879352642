@media screen and (max-width: 960px) {
    div:is(#responsibilities-list, #technologies-list) {
        flex-direction: column;
    }

    div:is(#responsibilities-list, #technologies-list) ul {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 960px) {
    div:is(#responsibilities-list, #technologies-list) {
        flex-direction: row;
    }
}