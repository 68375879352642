@media screen and (max-width: 1279px) {
    #main-content {
        margin: 2.5vh 2.5vw;
    }

    /* This is just an easy way to put the navigation at the bottom. */
    .content {
        --navigation-height: 10vh;
        
        margin-bottom: var(--navigation-height);
        flex-direction: column-reverse;
    }
}

@media screen and (min-width: 1280px) {
    #main-content {
        margin: 2.5vh 10vw;
    }

    .content {
        flex-direction: column;
    }
}