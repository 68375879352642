.collapsible-section {
    display: flex;
    position: relative;

    height: fit-content;
    width: 100%;

    flex-direction: column;
}

.collapsible-header {
    display: flex;
    
    align-items: center;
}

.collapsible-header p {
    margin: 0;
}

.collapsible-header .collapse-icon {
    transition: all 0.5s ease-in-out;
}

.collapsible-header .collapse-icon.expanded-icon {
    /* color: var(--dark-theme-light-orange); */
    transform: rotateZ(-180deg);
}

.collapsible-header div.separator-div::after {
    border-bottom: none;
}

.collapsible-header .highlight-container {
    margin: 0;
    padding: 0;

    margin-right: 2.5vw;
}

.collapsible-header:hover .highlight-image {
    z-index: -1;
    transform: scale(1);
}

.collapsible-header:hover .highlight-card .highlight-card-info {
    color: var(--dark-theme-orange);
}

.collapsible-content {
    /* --content-height is set in the JS as we need to know how large the content is, in pixels, in order for us to have a transition. */
    max-height: var(--content-height);
    opacity: 1;

    overflow: hidden;
    transition: all 0.75s ease-in-out;
}

.collapsible-content.minimised-content {
    max-height: 0;
    
    opacity: 0;
}

.header-highlight.highlight-container {
    margin: 0;

    width: 100%;
}

.header-highlight.highlight-container::after {
    border-bottom: none;
}

.header-highlight.highlight-container .header-image-overlay {
    flex: 1;
}

.header-image-overlay {
    position: relative;
}

.header-image-overlay > .collapse-icon {
    position: absolute;

    bottom: 1vh;
}

.header-summary {
    color: var(--dark-theme-light-orange);
    font-weight: bold;
    font-style: italic;
}