.player-wrapper {
    display: flex;
    position: relative;
}

.player-wrapper > * {
    flex: 1;
}

.player-buffer-container {
    display: flex;
    position: absolute;

    width: 100%;
    height: 100%;

    justify-content: center;
    align-items: center;
}

.player-buffer-container.player-buffer-hidden {
    display: none;
}

.player-buffer-container > .player-buffer-overlay {
    position: absolute;
    
    width: 100%;
    height: 100%;
    
    background-color: var(--bg-colour);
    opacity: 0.625;
}