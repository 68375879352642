#waffle-overview > div {
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    text-align: center;
}

#waffle-overview > div > h1 {
    margin-bottom: 2.5vh;
}

#waffle-overview > div > p {
    margin-bottom: 0;
}

#waffle-intro {
    display: flex;
    margin-bottom: 0;
}

#waffle-motivation {
    display: flex;
    
    flex-direction: row;
}

#waffle-outro {
    display: flex;
    
    align-items: center;
}

#waffle-outro > * {
    flex: 1;
}

#waffle-outro > #waffle-outro-images {
    display: flex;
    
    margin-top: 2.5vh;

    width: 100%;
}

#waffle-outro-text {
    display: flex;
    
    flex-direction: column;
    align-items: center;
}

#waffle-outro-text > p {
    margin-top: 2.5vh;
    text-align: center;
}

#waffle-outro-text > p:last-of-type {
    font-size: 0.75rem;
    margin-bottom: 0;
}

#waffle-outro-text > .linked-icon-wrapper,
#waffle-outro-text > .linked-icon-wrapper svg {
    margin: 0;
}