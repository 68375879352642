.linked-icon-wrapper {
    position: relative;
    
    margin-left: 1vw;

    width: -moz-fit-content;

    width: fit-content;
}

.linked-icon-wrapper:first-of-type {
    margin-top: 1.5vh;
}

.linked-icon-wrapper:not(:first-of-type) {
    margin-top: 1vh;
}

.linked-icon {
    display: flex;
    flex-direction: row;

    align-items: baseline;

    color: var(--dark-theme-orange);
    text-decoration: none;

    transition: all 0.5s ease-in-out;
}

.linked-icon.selected-linked-icon {
    color: var(--dark-theme-font-colour);
}

.linked-icon:hover {
    text-decoration: none;

    color: var(--dark-theme-font-colour);
    --fa-primary-color: var(--dark-theme-font-colour);
}

.linked-icon > svg {
    margin-right: 0.5rem;
}

.linked-icon > p {
    flex: 3 1;
}

/* I don't want to drop an !important here, so I'm being overly specific. */
.linked-icon-wrapper > .linked-icon > p.link-description {
    margin-bottom: 0;
    
    font-size: 1.5em;
    font-family: "desire-pro", serif;
    letter-spacing: 1px;
}
#navigation-menu {
    display: flex;

    justify-content: center;

    /*
        This is here so it doesn't jump around on mobile if the browser does some nonsense that alters the bottom.
        I've seen said nonsense on Firefox, and Firefox Focus.
    */
    transition: all 0.5s ease-in-out;
}

#navigation-menu > .linked-icon-wrapper {
    flex: 1 1;
}

#navigation-menu .linked-icon {
    padding-left: 0.75vw;
}

#navigation-menu .linked-icon > svg {
    /* min-width allows all icons to be the same size, instead of some being thinner than others. */
    min-width: 1.5em;
}
@media screen and (max-width: 1279px) {
    #navigation-menu {
        position: fixed;

        width: 100%;

        bottom: 0;
        background-color: var(--bg-colour);

        /* Need this to be large enough such that it's above everything else. */
        z-index: 32;

        flex-direction: row;

        align-items: center;
    }

    /*
        I don't see a reason to make these responsive changes to the LinkedIcon component.
        Those components SHOULDN'T need to intrinsically worry about being responsive.
        Only the components that use them should be worried about it.
        If we end up having the same code copy-pasted in different components, we can look to making it a CSS class.
    */
    #navigation-menu .linked-icon {
        flex-direction: column;

        align-items: center;
    }

    #navigation-menu .linked-icon > svg {
        margin-right: 0;
        padding-top: 1.25vh;

        font-size: 2em;
    }

    #navigation-menu > .linked-icon-wrapper {
        margin: 0;
        padding-bottom: 0.25vh;
    }

    #navigation-menu p.link-description {
        margin: 0vh 2.5vw;
    }
}

@media screen and (min-width: 1280px) {
    #navigation-menu {
        position: fixed;
        
        flex-direction: column;
    }

    #navigation-menu .linked-icon.selected-linked-icon::before {
        border-left: 1.5px solid var(--dark-theme-orange);
    }

    #navigation-menu .linked-icon::before {
        content: "";
        position: absolute;
        
        height: 100%;
        left: 0px;
    
        box-sizing: inherit;
        border-left: 1.5px solid var(--dark-theme-font-colour);
    }

    #navigation-menu .linked-icon:not(.selected-linked-icon)::after {
        content: "";
        position: absolute; 
        
        height: 0%;
        left: 0px;
        
        box-sizing: inherit;
        border-left: 1.5px solid transparent;
    
        transition: height 0.5s, border-color 0.5s; 
    }
    
    #navigation-menu .linked-icon:not(.selected-linked-icon):hover::after {
        height: 100%;
    
        border-color: var(--dark-theme-orange);
    }
}
#main-content {
    display: flex;

    flex: 5 1;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}

#menu-icon {
    position: absolute;
    
    margin-top: 1.5vh;
    margin-left: 1vw;
}

#menu-icon:hover {
    cursor: pointer;
}

#menu-icon svg {
    transition: all 0.75s ease-in-out;
}

#menu-icon:hover svg {
    color: var(--dark-theme-orange);
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;

    flex: 1 1;

    margin: 0;
    margin-bottom: 2.5vh;

    max-width: 100%;

    font-family: "Roboto", sans-serif;
    color: var(--dark-theme-font-colour);
}

.content h1:not(:first-child),
.content h2, 
.content h3 { 
    margin-top: 4.25vh;
}

.content h1,
.content h2, 
.content h3 {
    margin-bottom: 1vh;
    border-bottom: 1px solid var(--dark-theme-orange);

    font-family: 'desire-pro', serif;
    text-align: left;
    
    width: -moz-fit-content;
    
    width: fit-content;
}

.content-gutter {
    flex: 1 1
}

.padless {
    padding: 0 !important;
}

.padless .row {
    margin: 0;
}
@media screen and (max-width: 1279px) {
    #main-content {
        margin: 2.5vh 2.5vw;
    }

    /* This is just an easy way to put the navigation at the bottom. */
    .content {
        --navigation-height: 10vh;
        
        margin-bottom: var(--navigation-height);
        flex-direction: column-reverse;
    }
}

@media screen and (min-width: 1280px) {
    #main-content {
        margin: 2.5vh 10vw;
    }

    .content {
        flex-direction: column;
    }
}
#friendly {
    display: flex;
    flex: 1 1;
    flex-direction: column;

    border: none;

    align-items: center;
}

#friendly > * {
    display: flex;
    flex: 1 1;
}

#greeting {
    display: flex;
    flex: 1 1;

    flex-direction: column;
    justify-content: center;

    padding-bottom: 4.5rem;
    padding-top: 4.5rem;
}

#greeting > h1 > a {
    text-decoration: none;
    color: inherit;
    cursor: default;
}

#greeting h1,
#greeting h2,
#greeting h3 {
    border-bottom: none;
}

#greeting h1:not(:first-child),
#greeting h2, 
#greeting h3 { 
    margin-top: 0vh;
}

#greeting h3 {
    font-size: 1.5rem;
}

#mii-contact-links {
    display: flex;
    flex-direction: row;

    align-items: baseline;
    justify-content: center;
}

#projects-overview > div,
#playground-overview > div {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}

#projects-overview > div > p,
#playground-overview > div > p {
    display: inline-block;
    text-align: center;

    margin-bottom: 0;
}

#projects-overview > svg {
    margin-top: 1vh;
}

#projects-highlights .highlight-container b {
    color: var(--dark-theme-light-orange);
    font-style: italic;
}

.mii-hidden {
    display: none;
    height: 0%;
}
@media screen and (max-width: 1279px) {
    #greeting h1,
    #greeting h2,
    #greeting h3 {
        width: 100%;
        text-align: center;
    }

    #greeting .linked-icon > svg {
        font-size: 2em;
    }

    #projects-highlights .highlight-card {
        width: 75%;
    }
}

@media screen and (min-width: 1280px) {
    #greeting h1,
    #greeting h2,
    #greeting h3 {
        text-align: left;
    }

    #playground.content {
        margin-bottom: 0;
    }
}
.fade-in {
    animation: fade-in 0.7s;
}

.fade-in.delay {
    animation: fade-in-delay 1.4s;
}

.fade-in.long-delay {
    animation: fade-in-long-delay 2.1s;
}

.load-post-greeting {
    animation: fade-in-post-greeting 2.8s;
}

.hello-glow {
    animation: glowing-text 3s infinite alternate;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-in-delay {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in-long-delay {
    0% {
        opacity: 0;
    }

    33% {
        opacity: 0;
    }

    67% {
        opacity: 0;
    }
    
    100% {
        opacity: 1;
    }
}

@keyframes fade-in-post-greeting {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes glowing-text {
    80% {
        color: var(--dark-theme-font-colour);
    }

    100% {
        color: var(--dark-theme-orange);
    }
}
.highlight-container {
    position: relative;
    display: flex;
    
    width: 75%;
}

.highlight-container::after {
    position: absolute;
    content: "";

    bottom: 0;
    left: 31.25%;
    width: 37.5%;

    border-bottom: 1px solid var(--dark-theme-orange);
}

.highlight-card {
    display: flex;
    position: relative;

    flex: 1 1;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.highlight-card > a.highlight-link {
    width: -moz-fit-content;
    width: fit-content;
}

.highlight-card > a.highlight-svg-link {
    width: 100%;
}

/* Don't know why, but, having the asterisk like this looks weird :3 */
.highlight-card * {
    transition: all 0.75s ease-in-out;
}

.highlight-card-info {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    
    z-index: 2;
    color: var(--dark-theme-font-colour);
}

.highlight-card-info.hovered-image {
    color: var(--dark-theme-orange);
}

.highlight-description {
    flex: 1 1;
    align-self: center;

    text-align: center;
}

.highlight-description > p {
    margin: 0;
}

.highlight-image {
    border-radius: 5px;
}

.highlight-image.highlight-svg-image {
    /* SVGs are rendered fine if they have a project path, since the arrow helps determing the size of the SVG. */
    /* But I want something generic, and width: 100% didn't give me that. */
    
    min-height: 10vh;
}

.highlight-image.hovered-image {
    z-index: -1;
    transform: scale(1);
}

.highlight-image-container {
    position: relative;
    overflow: hidden;
}

.highlight-image-overlay {
    position: absolute;

    bottom: 0;

    height: 100%;
    width: 100%;

    z-index: 1;

    background-image: linear-gradient(to top, var(--bg-colour) calc(1.75rem), rgba(255, 0, 0, 0));
    opacity: 0.8;
}

h3.highlight-title {
    position: absolute;
    margin: 0;
    padding: 0;

    bottom: 5%;
    left: 5%;

    border-bottom: none;
}

.highlight-card-arrow {
    position: absolute;

    bottom: 5%;
    right: 5%;
}
@media screen and (max-height: 500px) {
    .highlight-container {
        margin-top: 5vh;
        padding-bottom: 5vh;
    }

    .highlight-card {
        width: -moz-fit-content;
        width: fit-content;
        align-self: center;
    }

    .highlight-image {
        height: 50vh;
    }
}

@media screen and (min-height: 500px) {
    .highlight-image {
        width: 100%;
        max-height: 30vh;
    }
}

@media screen and (max-width: 960px) {
    .highlight-container {
        flex-direction: column;
        align-items: center;
    }

    .highlight-description {
        margin-top: 2vh;
    }
}

@media screen and (min-width: 960px) {
    .highlight-container {
        flex-direction: row;
    }

    /* This looks weird if reversed on a phone, or other small screen device. */
    .highlight-container.reversed-highlight {
        flex-direction: row-reverse;
    }
    
    :not(.reversed-highlight) .highlight-description {
        margin-left: 10%;
    }
    
    .reversed-highlight .highlight-description {
        margin-right: 10%;
    }
    
    .highlight-image {
        transform: scale(1.2);
    }
}
section.separator-section {
    display: flex;
    position: relative;
    
    flex-direction: column;
    flex: 1 1;

    width: 100%;

    padding-bottom: 2.5vh;
    margin-bottom: 2.5vh;
}

section.separator-section::after {
    --width: 25%;

    position: absolute;
    content: "";

    bottom: 0;

    width: var(--width);
    left: calc((100% - var(--width)) / 2);

    border-bottom: 1px solid var(--dark-theme-orange);
}

section.separator-section > .separator-div:last-of-type {
    margin-bottom: 0;
}
.collapsible-section {
    display: flex;
    position: relative;

    height: -moz-fit-content;

    height: fit-content;
    width: 100%;

    flex-direction: column;
}

.collapsible-header {
    display: flex;
    
    align-items: center;
}

.collapsible-header p {
    margin: 0;
}

.collapsible-header .collapse-icon {
    transition: all 0.5s ease-in-out;
}

.collapsible-header .collapse-icon.expanded-icon {
    /* color: var(--dark-theme-light-orange); */
    transform: rotateZ(-180deg);
}

.collapsible-header div.separator-div::after {
    border-bottom: none;
}

.collapsible-header .highlight-container {
    margin: 0;
    padding: 0;

    margin-right: 2.5vw;
}

.collapsible-header:hover .highlight-image {
    z-index: -1;
    transform: scale(1);
}

.collapsible-header:hover .highlight-card .highlight-card-info {
    color: var(--dark-theme-orange);
}

.collapsible-content {
    /* --content-height is set in the JS as we need to know how large the content is, in pixels, in order for us to have a transition. */
    max-height: var(--content-height);
    opacity: 1;

    overflow: hidden;
    transition: all 0.75s ease-in-out;
}

.collapsible-content.minimised-content {
    max-height: 0;
    
    opacity: 0;
}

.header-highlight.highlight-container {
    margin: 0;

    width: 100%;
}

.header-highlight.highlight-container::after {
    border-bottom: none;
}

.header-highlight.highlight-container .header-image-overlay {
    flex: 1 1;
}

.header-image-overlay {
    position: relative;
}

.header-image-overlay > .collapse-icon {
    position: absolute;

    bottom: 1vh;
}

.header-summary {
    color: var(--dark-theme-light-orange);
    font-weight: bold;
    font-style: italic;
}
@media screen and (max-width: 960px) {
    .collapsible-header {
        flex-direction: column;
    }

    .collapsible-header .collapse-icon {
        font-size: 2em;
    }

    .collapsible-header .highlight-container {
        margin-bottom: 2.5vh;
    }

    .collapsible-header > .collapse-icon {
        display: none;
    }

    .header-image-overlay > div.highlight-card-info > .collapse-icon {
        display: initial;
    }
}

@media screen and (min-width: 960px) {
    .collapsible-header {
        flex-direction: row;
    }

    .collapsible-header .highlight-container {
        margin-right: 2.5vw;
    }

    .collapsible-header > .collapse-icon {
        display: initial;
    }

    .header-image-overlay > div.highlight-card-info > .collapse-icon {
        display: none;
    }
}
div.separator-div {
    position: relative;
    
    flex: 1 1;

    width: 100%;

    margin-bottom: 2.5vh;
    padding-bottom: 2.5vh;
}

div.separator-div::after {
    --width: 12.5%;

    position: absolute;
    content: "";

    bottom: 0;

    width: var(--width);
    left: calc((100% - var(--width)) / 2);

    border-bottom: 1px solid var(--dark-theme-font-colour);
    opacity: 0.25;
}
#career {
    min-height: calc(100vh - var(--navigation-height));
}

#career-intro {
    flex: 0 1;
    align-self: flex-start;
}

#career-intro > div {
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#career-intro h1 {
    margin-bottom: 2.5vh;
}

#career-intro p {
    margin: 0;
}

#everbridge {
    flex: 0 1;
}

div:is(#overview, #responsibilities, #technologies) > h2 {
    margin-top: 0;
}

div:is(#responsibilities-list, #technologies-list) {
    display: flex;
}

div:is(#responsibilities-list, #technologies-list) > * {
    flex: 1 1;
}

div:is(#responsibilities-list, #technologies-list) ul {
    list-style: none;
}

div:is(#responsibilities-list, #technologies-list) ul li::before {
    display: inline-block;
    content: "\2012";

    margin-left: -1em;
    width: 1em;

    font-weight: bold;

    color: var(--dark-theme-light-orange);
}
@media screen and (max-width: 960px) {
    div:is(#responsibilities-list, #technologies-list) {
        flex-direction: column;
    }

    div:is(#responsibilities-list, #technologies-list) ul {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 960px) {
    div:is(#responsibilities-list, #technologies-list) {
        flex-direction: row;
    }
}
.text-link {
    --transition-duration: 0.375s;

    position: relative;

    color: var(--dark-theme-orange);
    text-decoration: none;

    transition: all var(--transition-duration);
}

.text-link:hover {
    color: var(--dark-theme-font-colour);
}

.text-link::after {
    content: "";
    position: absolute;

    bottom: -1.5px;
    left: 0px;
    
    height: 1px;
    width: 0%;
    
    box-sizing: inherit;
    border: 1px solid transparent;

    transition: all var(--transition-duration);
}

.text-link:hover::after {
    width: 100%;

    border-color: var(--dark-theme-orange);
}
div.loader {
    display: flex;
    
    justify-content: center;
    align-items: center;

    color: var(--dark-theme-orange);

    animation: fa-spin 1.25s infinite linear;
}
.spotify-player {
    display: flex;
    flex-direction: column;
}
@media screen and (max-width: 959px) {
    .spotify-player > iframe {
        height: 80px;
    }
}

@media screen and (min-width: 960px) {
    .spotify-player > iframe {
        height: 352px;
    }
}
.most-played-track,
.most-recent-track {
    position: relative;
    display: flex;
    
    flex: 1 1;
    flex-direction: column;
    
    margin: 0vh 2.5vw;
    margin-top: 2.5vh;

    justify-content: center;
    align-items: center;
}

.most-played-track > *,
.most-recent-track > * {
    flex: 1 1;
}

.most-played-track > h3,
.most-recent-track > h3 {
    margin-top: 0;
}

/* If the description is too large, we don't want the other components increasing in size. */
.most-played-track > h3,
.most-played-track > .spotify-player,
.most-recent-track > h3,
.most-played-track > .spotify-player {
    flex-grow: 0;
}

.most-played-track > p,
.most-recent-track > p {
    margin-top: 2.5vh;
    text-align: center;
}

.most-recent-track > p.currently-playing > svg {
    color: var(--dark-theme-orange);
}
@media screen and (max-width: 959px) {
    .most-played-track::after,
    .most-recent-track::after {
        position: absolute;
        content: "";
    
        bottom: 0;
        left: 31.25%;
        width: 37.5%;
    
        border-bottom: 1px solid var(--dark-theme-font-colour);
        opacity: 0.25;
    }
}
#music-taste-cards {
    display: flex;
    flex-direction: row;

    justify-content: center;
}

#taste-intro {
    display: flex;
    flex-direction: column;

    align-items: center;
    text-align: center;
}

#taste-intro > h2 {
    margin-top: 0vh;
}
@media screen and (max-width: 959px) {
    #music-taste-cards {
        flex-direction: column;
    }
}

@media screen and (min-width: 960px) {
    #music-taste-cards {
        flex-direction: row;
    }

    #music-taste-cards > div:first-of-type {
        margin-left: 0vw;
    }
    
    #music-taste-cards > div:last-of-type {
        margin-right: 0vw;
    }    
}
.player-wrapper {
    display: flex;
    position: relative;
}

.player-wrapper > * {
    flex: 1 1;
}

.player-buffer-container {
    display: flex;
    position: absolute;

    width: 100%;
    height: 100%;

    justify-content: center;
    align-items: center;
}

.player-buffer-container.player-buffer-hidden {
    display: none;
}

.player-buffer-container > .player-buffer-overlay {
    position: absolute;
    
    width: 100%;
    height: 100%;
    
    background-color: var(--bg-colour);
    opacity: 0.625;
}
.content.bloopy-content #main-content p {
    margin-top: 1vh;

    text-align: center;
}

#bloopy-overview > div {
    display: flex;

    flex-direction: column;
    align-items: center;

    text-align: center;
}

#bloopy-overview > div > h1 {
    width: -moz-fit-content;
    width: fit-content;
}

#bloopy-overview > div > p {
    margin-top: 2.5vh;
    margin-bottom: 0;
}

#bloopy-graphics > div {
    position: relative;
    flex: 1 1;

    width: 100%;
    padding-bottom: 2.5vh;
}

#bloopy-graphics > div::after {
    --width: 12.5%;

    position: absolute;
    content: "";

    bottom: 0;

    width: var(--width);
    left: calc((100% - var(--width)) / 2);

    border-bottom: 1px solid var(--dark-theme-font-colour);
    opacity: 0.25;
}

#bloopy-mascot {
    display: flex;

    flex-direction: column;

    text-align: center;
}

#bloopy-mascot .highlight-card {
    margin: 2.5vh 0;
}

#bloopy-animations {
    display: flex;
    margin: 2.5vh 0;

    align-items: center;
    justify-content: center;

    text-align: center;
}

#bloopy-animations > div {
    margin-bottom: 2.5vh;
}

#bloopy-gameplay > div > .player-wrapper {
    --player-height: 75vh;
    
    height: var(--player-height);

    /*
        The "padding" at the bottom that comes with the video is 10% of the video's height.
        The "padding" at the top is only 5% of the video's height.
        This is just making the difference consistent.
    */
    padding-top: calc(var(--player-height) * 0.05);
}

#bloopy-gameplay p,
#bloopy-outro h2 {
    margin-top: 0;
}

#bloopy-outro > div {
    display: flex;

    flex-direction: column;
    align-items: center;
}

#bloopy-outro p {
    margin-bottom: 0;
}

#bloopy-outro #link-container {
    display: flex;

    margin-top: 1em;

    flex-direction: row;
    justify-content: center;
    align-items: baseline;
}

#bloopy-graphics .separator-div:last-of-type,
#bloopy-gameplay .separator-div:last-of-type,
#bloopy-outro .separator-div:last-of-type{
    margin-bottom: 0vh;
}
@media screen and (max-width: 640px) {
    #bloopy-animations iframe {
        --width: 80vw;
        
        width: var(--width);
        height: calc(var(--width) * (9 / 16));
    }

    #bloopy-outro #link-container .linked-icon-wrapper {
        margin: 0 5vw;
    }
}

@media screen and (min-width: 641px) {
    #bloopy-animations iframe {
        width: 560;
        height: 315;
    }

    #bloopy-outro #link-container .linked-icon-wrapper {
        margin: 0 2.5vw;
    }
}

@media screen and (max-width: 1279px) {
    #bloopy-animations {
        flex-direction: column;
    }

    #bloopy-animations > p {
        flex: 1 1;
    }

    #bloopy-animations > div {
        flex: 2 1;
        flex-basis: auto;
    }
}

@media screen and (min-width: 1280px) {
    #bloopy-animations {
        flex-direction: row;
    }

    #bloopy-animations > p {
        flex: 1 1;
    }

    #bloopy-animations > div {
        flex: 1 1;
    }
}
#bu {
    --content-width: 62.5%;
}

#bu h2 {
    margin-top: 0;
}

#bu-overview > div {
    display: flex;

    flex: 1 1;
    flex-direction: column;
    align-items: center;

    text-align: center;
}

#bu-overview video {
    width: 100%;
}

#bu-overview > div > p {
    margin-top: 1.5vh;
    margin-bottom: 0vh;
}

#bu-overview > div > p > span {
    color: var(--dark-theme-orange);
    
    font-style: italic;
    font-weight: bold;
}

#bu-intro {
    position: relative;
    display: flex;

    flex: 1 1;
    flex-direction: column;
    align-items: center;

    max-width: 100%;

    text-align: center;
}

#bu-intro > #bu-intro-video-wrapper::after {
    display: none;
}

#bu-intro > #bu-intro-video-wrapper,
#bu-intro-video-wrapper > #bu-intro-video {
    position: relative;
}

#bu-intro-video-wrapper > #bu-intro-video video {
    max-width: 100%;
}

#bu-mini-player-overlay {
    position: absolute;

    top: 0;
    
    height: 100%;
    width: 100%;

    z-index: 32;
}

#bu-mini-player-overlay > #bu-overlay-colour {
    position: absolute;

    top: 0;

    height: 100%;
    width: 100%;

    background-color: var(--bg-colour);
    opacity: 0.7;

    z-index: -1;
}

#bu-mini-player-overlay > #bu-overlay-content {
    display: flex;

    height: 100%;
}

#bu-mini-player-overlay > #bu-overlay-content > div {
    display: flex;
    flex: 1 1;

    flex-direction: column;
    justify-content: center;

    text-align: left;

    opacity: 0.9;
}

#bu-mini-player-overlay > #bu-overlay-content > div > * {
    margin: 0;
}

#bu-intro-classification {
    display: flex;
}

#bu-intro-classification > #bu-classification-overview {
    display: flex;

    flex: 1 1;
    flex-direction: column;
}

#bu-intro-classification > #bu-classification-overview > h2 {
    margin-bottom: 2.5vh;
}

#bu-intro-classification > #bu-classification-circles {
    --animation-duration: 10s;
    --circle-diameter: 25px;
    --circle-margin: calc(var(--circle-diameter) / 4);

    --classification-circles-height: calc(var(--num-circles) * (var(--circle-diameter) + var(--circle-margin)));
    
    position: relative;
    display: flex;
    
    flex: 1 1;
    flex-direction: column;

    align-items: center;
    
    min-height: var(--classification-circles-height);
}

#bu-classification-circles .bu-classification-circle {
    position: absolute;

    height: var(--circle-diameter);
    width: var(--circle-diameter);

    border-radius: 50%;
    opacity: 0.75;
}

#bu-classification-circles .bu-classification-circle.classifying-circle {
    --classified-top-pos: 0;
    --classified-left-pos: 0;
    
    animation-name: circle-glow, circle-classify;
    animation-duration: var(--animation-duration), calc(var(--animation-duration) / 3);
    animation-timing-function: ease-in-out, ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

/* 
    Groups are all the circles which have the same animation-delay.
*/
#bu-classification-circles .bu-classification-circle.classifying-circle.classified-top-group {
    animation-delay: 0s, 0s;
}

#bu-classification-circles .bu-classification-circle.classifying-circle.classified-middle-group {
    animation-delay: calc(0s - var(--animation-duration) / 3), 0s;
}

#bu-classification-circles .bu-classification-circle.classifying-circle.classified-bottom-group {
    animation-delay: calc(0s - 2 * var(--animation-duration) / 3), 0s;
}

/*
    Columns are unaffected by grouping.
    All classified circles in the left column will have the same x coordinate.
    The same applies to the right column.
*/
#bu-classification-circles .bu-classification-circle.classifying-circle.classified-left-col {
    --classified-left-pos: calc(100% - (var(--circle-diameter) * 2) - var(--circle-margin));
}

#bu-classification-circles .bu-classification-circle.classifying-circle.classified-right-col {
    --classified-left-pos: calc(100% - (var(--circle-diameter)));
}

/* 
    Putting all the circles in the top group at the top.
*/
#bu-classification-circles .bu-classification-circle.classifying-circle.classified-top-group.classified-top-row {
    --classified-top-pos: 0;
}

#bu-classification-circles .bu-classification-circle.classifying-circle.classified-top-group.classified-bottom-row {
    --classified-top-pos: calc(var(--circle-diameter) + var(--circle-margin));
}

/* 
    Putting all the circles in the middle group at the bottom.
*/
#bu-classification-circles .bu-classification-circle.classifying-circle.classified-middle-group.classified-top-row {
    --classified-top-pos: calc(50% - var(--circle-diameter) / 2 - var(--circle-margin));
}

#bu-classification-circles .bu-classification-circle.classifying-circle.classified-middle-group.classified-bottom-row {
    --classified-top-pos: calc(50% + var(--circle-diameter) / 2);
}

/* 
    Putting all the circles in the bottom group at the bottom.
*/
#bu-classification-circles .bu-classification-circle.classifying-circle.classified-bottom-group.classified-top-row {
    --classified-top-pos: calc(100% - 2 * var(--circle-diameter) - var(--circle-margin));
}

#bu-classification-circles .bu-classification-circle.classifying-circle.classified-bottom-group.classified-bottom-row {
    --classified-top-pos: calc(100% - var(--circle-diameter));
}

/* 
    Vertically positions the circles, based on their index.
*/
#bu-classification-circles .bu-classification-circle.classifying-circle {
    /* --circle-index is set in the parent div within the JSX code. */
    --classified-initial-top-pos: calc(var(--column-top-pos) + (var(--circle-index) * (var(--circle-diameter) + var(--circle-margin))));
    --classified-initial-left-pos: calc(var(--circle-column-index) * (var(--circle-diameter) + var(--circle-margin)));
}

#bu-outro {
    display: flex;

    flex-direction: column;
    align-items: center;
}

#bu-summary p,
#bu-outro p {
    margin: 0;
}

#bu-outro-links {
    display: flex;

    align-items: center;
    flex-direction: column;

    text-align: center;
}

#bu-outro-link-container {
    display: flex;

    margin-top: 1em;

    flex-direction: row;

    justify-content: center;
    align-items: baseline;
}

@keyframes circle-glow {
    0% {
        background-color: var(--dark-theme-orange);
    }

    20% {
        background-color: #EE5253;
    }

    40% {
        background-color: #8C7AE6;
    }

    60% {
        background-color: #0984E3;
    }

    80% {
        background-color: #55EFC4;
    }

    100% {
        background-color: #FFFA65;
    }
}

@keyframes circle-classify {
    0% {
        top: var(--classified-initial-top-pos);
        left: var(--classified-initial-left-pos);
    }
    
    15% {
        top: var(--classified-initial-top-pos);
        left: var(--classified-initial-left-pos);
    }

    85% {
        top: var(--classified-top-pos);
        left: var(--classified-left-pos);
    }

    100% {
        top: var(--classified-top-pos);
        left: var(--classified-left-pos);
    }
}
@media screen and (max-width: 640px) {
    #bu-mini-player-overlay > #bu-overlay-content > div {
        align-items: center;
    }

    #bu-intro-video-wrapper,
    #bu-intro-video-wrapper > p {
        margin: 0;
    }

    #bu-overlay-content p {
        display: none;
        height: 0;
    }
    
    #bu-intro-classification {
        flex-direction: column;
    }

    #bu-intro-classification > #bu-classification-overview {
        align-items: center;
    }

    #bu-summary > div {
        display: flex;

        text-align: center;
        flex-direction: column;
        align-items: center;
    }

    #bu-outro-link-container .linked-icon-wrapper {
        margin: 0 5vw;
    }
}

@media screen and (min-width: 641px) {
    #bu-intro-video-wrapper > p {
        display: none;
    }

    #bu-mini-player-overlay > #bu-overlay-content > div > * {
        margin: 0;
        margin-left: 2.5vw;
    }

    #bu-mini-player-overlay > #bu-overlay-content > div > h2 {
        margin-bottom: 2.5vh;
    }

    #bu-overlay-content p {
        display: initial;
    }

    #bu-intro-classification {
        flex-direction: column;

        text-align: left;
        align-items: center;
    }

    #bu-classification-overview {
        width: var(--content-width);
    }

    #bu-intro-classification > #bu-classification-circles {
        width: var(--content-width);
    }

    #bu-summary {
        width: var(--content-width);    
    }

    #bu-outro-link-container .linked-icon-wrapper {
        margin: 0 2.5vw;
    }
}
#waffle-overview > div {
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    text-align: center;
}

#waffle-overview > div > h1 {
    margin-bottom: 2.5vh;
}

#waffle-overview > div > p {
    margin-bottom: 0;
}

#waffle-intro {
    display: flex;
    margin-bottom: 0;
}

#waffle-motivation {
    display: flex;
    
    flex-direction: row;
}

#waffle-outro {
    display: flex;
    
    align-items: center;
}

#waffle-outro > * {
    flex: 1 1;
}

#waffle-outro > #waffle-outro-images {
    display: flex;
    
    margin-top: 2.5vh;

    width: 100%;
}

#waffle-outro-text {
    display: flex;
    
    flex-direction: column;
    align-items: center;
}

#waffle-outro-text > p {
    margin-top: 2.5vh;
    text-align: center;
}

#waffle-outro-text > p:last-of-type {
    font-size: 0.75rem;
    margin-bottom: 0;
}

#waffle-outro-text > .linked-icon-wrapper,
#waffle-outro-text > .linked-icon-wrapper svg {
    margin: 0;
}
@media screen and (max-width: 1279px) {
    #waffle-intro {
        flex-direction: row;
    }

    #waffle-outro > #waffle-outro-images {
        flex-direction: column;
    }

    #waffle-outro-images > .highlight-card:first-of-type {
        display: none;
    }

    #waffle-outro-images > .highlight-card:last-of-type {
        margin-top: 2.5vh;
    }
}

@media screen and (min-width: 1280px) {
    #waffle-intro {
        flex-direction: row;
    }

    #waffle-intro > .highlight-container > .highlight-description {
        margin: 0;
    }

    #waffle-outro > #waffle-outro-images {
        flex-direction: row;
    }
}
:root {
    /* --bg-colour: #353B48; */
    /* --bg-colour: #2F3640; */
    /* --bg-colour: #2f3542; */
    /* --dark-theme-purple: #474787; */
    
    --bg-colour-overlay: #F1F2F6;
    --bg-colour: #1C1E1F;
    
    --dark-theme-font-colour: #CCC;
    --dark-theme-orange: #E6AB5E;
    --dark-theme-light-orange: #EEC591;

    --dark-theme-font-colour-translucent: rgba(204, 204, 204, 0.25);
}
body {
    min-height: 100vh;
}

body, 
#site {
    display: flex;

    background-color: var(--bg-colour);
    margin: 0;
    padding: 0;

    --fa-primary-color: var(--dark-theme-orange);
    --fa-secondary-color: var(--dark-theme-font-colour);

    max-width: 100vw;
}

#root {
    display: flex;
    flex: 1 1;
}

#site {
    flex: 1 1;
}

blockquote {
    background-color: #DEDEDE;

    padding-left: 10px;
    border-left: solid 10px #FFC982;

    font-style: italic;
    color: #5E5E5E;
}

button:hover {
    cursor: pointer;
}

button:focus {
    outline: 0;
}

.center-text {
    text-align: center;
    vertical-align: middle;
}

.icon-link {
    display: inline-block;
    text-align: center;
    margin: 0 1vw;
}
