@media screen and (max-width: 640px) {
    #bloopy-animations iframe {
        --width: 80vw;
        
        width: var(--width);
        height: calc(var(--width) * (9 / 16));
    }

    #bloopy-outro #link-container .linked-icon-wrapper {
        margin: 0 5vw;
    }
}

@media screen and (min-width: 641px) {
    #bloopy-animations iframe {
        width: 560;
        height: 315;
    }

    #bloopy-outro #link-container .linked-icon-wrapper {
        margin: 0 2.5vw;
    }
}

@media screen and (max-width: 1279px) {
    #bloopy-animations {
        flex-direction: column;
    }

    #bloopy-animations > p {
        flex: 1;
    }

    #bloopy-animations > div {
        flex: 2;
        flex-basis: auto;
    }
}

@media screen and (min-width: 1280px) {
    #bloopy-animations {
        flex-direction: row;
    }

    #bloopy-animations > p {
        flex: 1;
    }

    #bloopy-animations > div {
        flex: 1;
    }
}