.text-link {
    --transition-duration: 0.375s;

    position: relative;

    color: var(--dark-theme-orange);
    text-decoration: none;

    transition: all var(--transition-duration);
}

.text-link:hover {
    color: var(--dark-theme-font-colour);
}

.text-link::after {
    content: "";
    position: absolute;

    bottom: -1.5px;
    left: 0px;
    
    height: 1px;
    width: 0%;
    
    box-sizing: inherit;
    border: 1px solid transparent;

    transition: all var(--transition-duration);
}

.text-link:hover::after {
    width: 100%;

    border-color: var(--dark-theme-orange);
}