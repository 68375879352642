@media screen and (max-width: 1279px) {
    #waffle-intro {
        flex-direction: row;
    }

    #waffle-outro > #waffle-outro-images {
        flex-direction: column;
    }

    #waffle-outro-images > .highlight-card:first-of-type {
        display: none;
    }

    #waffle-outro-images > .highlight-card:last-of-type {
        margin-top: 2.5vh;
    }
}

@media screen and (min-width: 1280px) {
    #waffle-intro {
        flex-direction: row;
    }

    #waffle-intro > .highlight-container > .highlight-description {
        margin: 0;
    }

    #waffle-outro > #waffle-outro-images {
        flex-direction: row;
    }
}