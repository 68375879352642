:root {
    /* --bg-colour: #353B48; */
    /* --bg-colour: #2F3640; */
    /* --bg-colour: #2f3542; */
    /* --dark-theme-purple: #474787; */
    
    --bg-colour-overlay: #F1F2F6;
    --bg-colour: #1C1E1F;
    
    --dark-theme-font-colour: #CCC;
    --dark-theme-orange: #E6AB5E;
    --dark-theme-light-orange: #EEC591;

    --dark-theme-font-colour-translucent: rgba(204, 204, 204, 0.25);
}