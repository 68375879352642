#navigation-menu {
    display: flex;

    justify-content: center;

    /*
        This is here so it doesn't jump around on mobile if the browser does some nonsense that alters the bottom.
        I've seen said nonsense on Firefox, and Firefox Focus.
    */
    transition: all 0.5s ease-in-out;
}

#navigation-menu > .linked-icon-wrapper {
    flex: 1;
}

#navigation-menu .linked-icon {
    padding-left: 0.75vw;
}

#navigation-menu .linked-icon > svg {
    /* min-width allows all icons to be the same size, instead of some being thinner than others. */
    min-width: 1.5em;
}