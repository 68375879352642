@media screen and (max-width: 959px) {
    .most-played-track::after,
    .most-recent-track::after {
        position: absolute;
        content: "";
    
        bottom: 0;
        left: 31.25%;
        width: 37.5%;
    
        border-bottom: 1px solid var(--dark-theme-font-colour);
        opacity: 0.25;
    }
}