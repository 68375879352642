#career {
    min-height: calc(100vh - var(--navigation-height));
}

#career-intro {
    flex: 0;
    align-self: flex-start;
}

#career-intro > div {
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#career-intro h1 {
    margin-bottom: 2.5vh;
}

#career-intro p {
    margin: 0;
}

#everbridge {
    flex: 0;
}

div:is(#overview, #responsibilities, #technologies) > h2 {
    margin-top: 0;
}

div:is(#responsibilities-list, #technologies-list) {
    display: flex;
}

div:is(#responsibilities-list, #technologies-list) > * {
    flex: 1;
}

div:is(#responsibilities-list, #technologies-list) ul {
    list-style: none;
}

div:is(#responsibilities-list, #technologies-list) ul li::before {
    display: inline-block;
    content: "\2012";

    margin-left: -1em;
    width: 1em;

    font-weight: bold;

    color: var(--dark-theme-light-orange);
}