@media screen and (max-width: 1279px) {
    #greeting h1,
    #greeting h2,
    #greeting h3 {
        width: 100%;
        text-align: center;
    }

    #greeting .linked-icon > svg {
        font-size: 2em;
    }

    #projects-highlights .highlight-card {
        width: 75%;
    }
}

@media screen and (min-width: 1280px) {
    #greeting h1,
    #greeting h2,
    #greeting h3 {
        text-align: left;
    }

    #playground.content {
        margin-bottom: 0;
    }
}