#music-taste-cards {
    display: flex;
    flex-direction: row;

    justify-content: center;
}

#taste-intro {
    display: flex;
    flex-direction: column;

    align-items: center;
    text-align: center;
}

#taste-intro > h2 {
    margin-top: 0vh;
}